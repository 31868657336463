<nav class="navbar navbar-toggleable-md navbar-light bg-faded fixed-top">
    <div class="container">
      <div class="navbar-header page-scroll">
        <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <a class="navbar-brand" href="#intro">Angular 4 Social Login</a>
      </div>
    </div>
  </nav>