<div class="jumbotron bg-transparent text-center">
    <div *ngIf="!user" class="card text-center">
      <h6 class="card-header">
        Social Login Demo
      </h6>
      <div class="card-block">
        <h4 class="card-title">Not signed in</h4>
        <p class="card-text">Sign in with</p>
      </div>
      <div class="card-block">
        <button class="btn btn-social-icon btn-google" (click)="signInWithGoogle()"><span class="fa fa-google"></span></button>
      </div>
    </div>
    <div *ngIf="user" class="card text-center">
      <h6 class="card-header">
        Social Login Demo
      </h6>
      <div class="card-block"></div>
      <img class="card-img-top img-responsive photo" src="{{ user.photoUrl }}">
      <div class="card-block">
        <h4 class="card-title">{{ user.name }}</h4>
        <p class="card-text">{{ user.email }}</p>
      </div>
      <div class="card-block">
        <button class="btn btn-danger" (click)="signOut()">Sign out</button>
      </div>
    </div>
  </div>